import CustomTable from "../../../utils/components/CustomTable/CustomTable"
import React from "react"
import {observer} from "mobx-react"
import {getComp} from "../../../utils/utils/DI"
import Typography from "@mui/material/Typography"
import {useIntl} from "react-intl"
import moment from "moment"
import TableControls from "./AddRequestTableControls"


function UploadFileTable() {
    const intl = useIntl()
    const {getSenderName} = getComp("SendersStore")
    const {
        selectedCollection, toggleMergePopup, selectedTable,
        page, handlePageChange, pageSize, onPageSizeChange
    } = getComp("FileUploadStore")
    const columns = [
        {
            field: "row",
            headerName: intl.formatMessage({id: "Номер строки"}),
            renderCellValue: params => params.row
        },
        {
            field: "surname",
            headerName: intl.formatMessage({id: "Фамилия"}),
            renderCellValue: params => params.surname
        },
        {
            field: "name",
            headerName: intl.formatMessage({id: "Имя"}),
            renderCellValue: params => params.name
        },
        {
            field: "patronymic",
            headerName: intl.formatMessage({id: "Отчество"}),
            renderCellValue: params => params.patronymic
        },
        {
            field: "phone",
            headerName: intl.formatMessage({id: "Телефон"}),
            renderCellValue: params => params.phone
        },
        {
            field: "territory",
            headerName: intl.formatMessage({id: "Территория"}),
            renderCellValue: params => params.territory
        },
        {
            field: "locality",
            headerName: intl.formatMessage({id: "Населенный пункт"}),
            minWidth: "100px",
            renderCellValue: params => params.locality
        },
        {
            field: "addressType",
            headerName: intl.formatMessage({id: "Улица/Квартал"}),
            renderCellValue: params => params.addressType
        },
        {
            field: "address",
            headerName: intl.formatMessage({id: "Наименование Улица/Квартал"}),
            minWidth: "100px",
            renderCellValue: params => params.address
        },
        {
            field: "house",
            headerName: intl.formatMessage({id: "Дом"}),
            renderCellValue: params => params.house
        },
        {
            field: "building",
            headerName: intl.formatMessage({id: "Корпус"}),
            minWidth: "60px",
            renderCellValue: params => params.building
        },
        {
            field: "apartment",
            headerName: intl.formatMessage({id: "Квартира"}),
            renderCellValue: params => params.apartment
        },
        {
            field: "reason",
            headerName: intl.formatMessage({id: "Установка/Замена"}),
            renderCellValue: params => params.reason
        },
        {
            field: "preferentialCategory",
            headerName: intl.formatMessage({id: "Льготная категория"}),
            minWidth: "150px",
            renderCellValue: params => params.preferentialCategory
        },
        {
            field: "date",
            headerName: intl.formatMessage({id: "Дата обращения"}),
            renderCellValue: params => params.date && moment(params.date, "YYYY.MM.DD").format("DD.MM.YYYY")
        },
        {
            field: "time",
            headerName: intl.formatMessage({id: "Время обращения"}),
            renderCellValue: params => params.time && moment(params.time, "HH:mm").format("HH:mm")
        },
        {
            field: "additionalInfo",
            headerName: intl.formatMessage({id: "Дополнительный комментарий"}),
            renderCell: params => <Typography variant={"body2"} sx={{whiteSpace: "nowrap"}}>
                {params.additionalInfo}
            </Typography>
        },
        {
            field: "equipmentId",
            headerName: intl.formatMessage({id: "Оборудование"}),
            renderCellValue: params => params.equipmentId
        },
        {
            field: "equipmentId",
            headerName: intl.formatMessage({id: "Источник"}),
            renderCellValue: params => getSenderName(params.senderId)
        }
    ]

    return (
        <>
            <TableControls/>
            <CustomTable
                rows={selectedCollection}
                columns={columns}
                total={selectedCollection.length}
                pageSize={pageSize}
                page={page}
                onPageChange={handlePageChange}
                onPageSizeChange={onPageSizeChange}
                paginateCollection
                onRowClick={selectedTable === 4 ? toggleMergePopup : null}
            />
        </>
    )
}

export default observer(UploadFileTable)