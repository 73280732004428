import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import InputField from '../../../components/customElements/InputField/InputField'
import { getComp } from '../../../utils/utils/DI'

function MergeForm({ data, errors, action, buttonText, buttonDisable }) {
    const intl = useIntl()
    const { allSenders, getSenderName } = getComp('SendersStore')
    const renderData = [
        { propName: 'name', label: intl.formatMessage({ id: 'Имя' }) },
        { propName: 'surname', label: intl.formatMessage({ id: 'Фамилия' }), capitalizeFirstLetter: true },
        { propName: 'patronymic', label: intl.formatMessage({ id: 'Отчество' }), capitalizeFirstLetter: true },
        { propName: 'phone', label: intl.formatMessage({ id: 'Телефон' }), capitalizeFirstLetter: true },
        { propName: 'territory', label: intl.formatMessage({ id: 'Страна' }) },
        { propName: 'locality', label: intl.formatMessage({ id: 'Населенный пункт' }) },
        { propName: 'addressType', label: intl.formatMessage({ id: 'Улица/квартал' }) },
        { propName: 'address', label: intl.formatMessage({ id: 'Улица' }) },
        { propName: 'house', label: intl.formatMessage({ id: 'Дом' }) },
        { propName: 'building', label: intl.formatMessage({ id: 'Корпус' }) },
        { propName: 'apartment', label: intl.formatMessage({ id: 'Квартира' }) },
        { propName: 'reason', label: intl.formatMessage({ id: 'Причина' }) },
        { propName: 'preferentialCategory', label: intl.formatMessage({ id: 'Льготная категория' }) },
        { propName: 'additionalInfo', label: intl.formatMessage({ id: 'Дополнительная информация' }) },
        { propName: 'senderId', label: intl.formatMessage({ id: 'Источник' }) },
        { propName: 'equipmentId', label: intl.formatMessage({ id: 'Оборудование' }) },
    ]

    return (
        <Box width={1}>
            <Box px={2} width={1} className={'jst-center'}>
                <Button
                    variant={'contained'}
                    onClick={action}
                    sx={{ whiteSpace: 'nowrap' }}
                    fullWidth
                    disabled={buttonDisable}
                >
                    {buttonText}
                </Button>
            </Box>
            <Box px={2} mt={2}>
                {renderData.map((el, i) => {
                    const { propName, label } = el
                    return (
                        <Box mt={i ? 1.5 : 0} key={i + ''}>
                            <InputField
                                value={
                                    data[propName]
                                        ? propName === 'senderId'
                                            ? getSenderName(data[propName])
                                            : data[propName]
                                        : ''
                                }
                                type={'text'}
                                fullWidth
                                label={label}
                                error={errors.find((key) => key === propName)}
                                withoutErrorText
                                readOnly={true}
                                capitalizeFirstLetter={el.capitalizeFirstLetter}
                            />
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default observer(MergeForm)
