import {observer} from "mobx-react"
import {useIntl} from "react-intl"
import {getComp} from "../../../utils/utils/DI"
import React from "react"
import TableControls from "../../../components/customElements/TableControls"

function AddRequestTableControls() {
    const {
        selectedTable, setSelectedTable, totalInserted, totalDuplicated, totalErrors,
        totalCollisions, totalUpdated
    } = getComp("FileUploadStore")

    const intl = useIntl()
    const data = [
        {
            text: intl.formatMessage({id: intl.formatMessage({id: "Загружено"})}),
            color: totalInserted ? "#16B025" : "#5F5F5F",
            total: totalInserted
        },
        {
            text: intl.formatMessage({id: intl.formatMessage({id: "Дубликаты"})}),
            color: totalDuplicated ? "#023A66" : "#5F5F5F",
            total: totalDuplicated
        },
        {
            text: intl.formatMessage({id: intl.formatMessage({id: "Обновлённые"})}),
            color: totalUpdated ? "#023A66" : "#5F5F5F",
            total: totalUpdated
        },
        {
            text: intl.formatMessage({id: intl.formatMessage({id: "Ошибки"})}),
            color: totalErrors ? "#E01717" : "#5F5F5F",
            total: totalErrors
        },
        {
            text: intl.formatMessage({id: intl.formatMessage({id: "Конфликты"})}),
            color: totalCollisions ? "#E01717" : "#5F5F5F",
            total: totalCollisions
        },
    ]
    return (
        <TableControls controls={data} selectedTab={selectedTable} setSelectedTab={setSelectedTable}/>
    )
}

export default observer(AddRequestTableControls)