import React, { useEffect, useState } from 'react'
import withWidth, { isWidthUp } from '@mui/material/Hidden/withWidth'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'
import { openModalFn } from '../../utils/controllers/ModalController'
import Stack from '@mui/material/Stack'
import { Button } from '@mui/material'
import uploadIcon from '../../assets/images/common/upload.svg'
import loadIcon from '../../assets/images/common/load.svg'
import { observer } from 'mobx-react'
import MergePopup from './components/MergePopup'
import UploadFileTable from './components/UploadFileTable'
import Box from '@mui/material/Box'
import { getComp } from '../../utils/utils/DI'
import { useLocation, useNavigate } from 'react-router-dom'
import { getStyles } from '../../utils/utils/commonUtils'
import commonStyles from '../../styles/commonStyles'
import { isAdmin } from '../../services/AccountService'

function AddRequests({ width, dealer }) {
    const classes = getStyles(commonStyles)
    const openUploadDrawer = () => {
        openModalFn['add-request']({ uploadFile: true, dealer: dealer })
    }
    const { selectedCollection, selectedTable, loadFile, clear } = getComp('FileUploadStore')
    const [isMergePopupOpen, setMergePopupOpen] = useState(false)
    let location = useLocation()
    let navigate = useNavigate()

    useEffect(() => {
        return () => clear()
    }, [location])

    return (
        <>
            <Stack
                direction={isWidthUp('md', width) ? 'row' : 'column'}
                className={isWidthUp('md', width) && 'aln-center jst-sb'}
                width={1}
                spacing={3}
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: '18px' }}>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        className={'aln-center'}
                        onClick={openUploadDrawer}
                        sx={{ ...classes.buttonActive, paddingLeft: '7px' }}
                    >
                        <img alt={'upload file'} src={uploadIcon} />

                        <Box>
                            <Typography variant={'h6'} sx={{ textTransform: 'uppercase' }}>
                                <FormattedMessage id={'Загрузить данные из файла'} />
                            </Typography>
                        </Box>
                    </Button>
                    {selectedCollection.length &&
                    (selectedTable === 1 || selectedTable === 3 || selectedTable === 4) ? (
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            className={'aln-center'}
                            sx={{ paddingLeft: '7px' }}
                            onClick={loadFile}
                        >
                            <img alt={''} src={loadIcon} />

                            <Box>
                                <Typography variant={'h6'} sx={{ textTransform: 'uppercase' }}>
                                    <FormattedMessage id={'Сохранить новый файл'} />
                                </Typography>
                            </Box>
                        </Button>
                    ) : null}
                </Box>
            </Stack>
            <Box name={'table'} mt={3}>
                <UploadFileTable />
            </Box>
            <MergePopup open={isMergePopupOpen} onClose={() => setMergePopupOpen(false)} />
        </>
    )
}

export default withWidth()(observer(AddRequests))
