import {observer} from "mobx-react"
import {Dialog} from "@mui/material"
import Box from "@mui/material/Box"
import MergeForm from "./MergeForm"
import {getComp} from "../../../utils/utils/DI"
import {useIntl} from "react-intl"
import React from "react"
import {FormattedMessage} from "react-intl/lib"
import Typography from "@mui/material/Typography"


function MergePopup() {
    const intl = useIntl()
    const {isMergePopupOpen, toggleMergePopup, mergeData, collisionsProps, replaceRequest} = getComp("FileUploadStore")
    return (<Dialog
        open={!!isMergePopupOpen}
        onClose={toggleMergePopup}
        sx={{maxWidth: "100vw"}}
        maxWidth={"lg"}
    >
        <Box p={3}>
            {mergeData && mergeData.fromDb && (mergeData.fromDb.dealerId || (mergeData.fromDb.status !== 1 && mergeData.fromDb.status !== 9) || mergeData.equipmentId) ?
                <Typography variant={"body1"} sx={{
                    color: "red",
                    position: "relative",
                    left: "50%",
                    transform: "translateX(-50%)",
                    marginBottom: "16px",
                    width: "fit-content"
                }}>
                    {mergeData.fromDb.dealerId ?
                        <FormattedMessage id={"Заявка назначена на дилера"}/> : mergeData.fromDb.equipmentId ?
                            <FormattedMessage id={"Заявке присвоено оборудование"}/> :
                            <FormattedMessage id={"Заявке присвоен статус"}/>}.
                </Typography> : null}

            <Box className={"fx-nowrap"}>
                <Box sx={{borderRight: "1px solid gray"}} width={1}>
                    <MergeForm
                        data={mergeData ? mergeData.fromDb : {}}
                        errors={collisionsProps}
                        buttonText={intl.formatMessage({id: "Оставить запись"})}
                        action={toggleMergePopup}
                    />
                </Box>

                <MergeForm
                    data={mergeData ? mergeData.fromFile : {}}
                    action={replaceRequest}
                    errors={collisionsProps}
                    buttonText={intl.formatMessage({id: "Заменить запись"})}
                    buttonDisable={mergeData && mergeData.fromDb && (mergeData.fromDb.dealerId || (mergeData.fromDb.status !== 1 && mergeData.fromDb.status !== 9) || mergeData.equipmentId)}
                />
            </Box>
        </Box>
    </Dialog>)
}

export default observer(MergePopup)